@mixin bshadow($vertical_length, $blur, $opacity) {
   -webkit-box-shadow: 0px $vertical_length $blur 0px rgba(0,0,0,$opacity);
    -moz-box-shadow: 0px $vertical_length $blur 0px rgba(0,0,0,$opacity);
    box-shadow: 0px $vertical_length $blur 0px rgba(0,0,0,$opacity);
}
@mixin bshadow-inset($vertical_length, $blur, $opacity) {
 -webkit-box-shadow: inset 0px $vertical_length $blur 0px rgba(0,0,0,$opacity);
    -moz-box-shadow: inset 0px $vertical_length $blur 0px rgba(0,0,0,$opacity);
    box-shadow: inset 0px $vertical_length $blur 0px rgba(0,0,0,$opacity);
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
