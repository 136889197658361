/* DEFAULT SCHEME
$gray-dark: #9aa7ba;
$blue-dark: #002554;
$blue-mid: #4386EB;
$blue-mid-alt: #2D476D;
$blue-light: #3477e2;
*/


/* EBBY COLORS */
$gray-light: #eaeef2;
$gray-dark: #9aa7ba;
$blue-dark: #4d0506;
$blue-mid: #a22621;
$blue-mid-alt: #a22621;
$blue-light: #d97a76;
