/* DEFAULT SCHEME
$gray-dark: #9aa7ba;
$blue-dark: #002554;
$blue-mid: #4386EB;
$blue-mid-alt: #2D476D;
$blue-light: #3477e2;
*/
/* EBBY COLORS */
html {
  --color-dark: #9aa7ba;
  --color-primary: #4d0506;
  --color-secondary: #a22621;
  --color-alternate: #a22621;
  --color-accent: #d97a76; }

body.gcc-connect {
  font-family: museo-sans, sans-serif;
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("/sf-8.jpg");
  overflow: auto;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh; }

a {
  color: var(--color-primary); }

a:hover {
  color: var(--color-primary); }

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: #FFF;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease; }

.btn-primary:hover {
  color: var(--color-secondary); }

.be-wrapper.with-sub-nav {
  padding-top: 100px; }

a.white-btn {
  color: white !important; }

/* HEADER STYLES */
.nav-icon-big span {
  font-size: 1.846rem;
  vertical-align: middle; }

.be-top-header .be-navbar-header .navbar-brand {
  background-size: contain;
  background-position: 0;
  margin-left: 15px; }

.be-top-header .be-navbar-header {
  padding: 5px 0; }

.be-nosidebar-left .be-top-header .be-navbar-header .navbar-brand {
  max-width: 400px;
  width: 400px; }

.logo-img {
  max-width: 250px;
  height: auto;
  padding: 0; }

.main-content-padding {
  padding: 2% 5%; }

/* TAB STYLES */
.splash-content-tabs.tab-container .nav-tabs > .nav-item > a {
  font-size: 1.5rem;
  padding: 15px 20px; }

.nav-tabs-classic > li.nav-item a.nav-link.active {
  border-radius: 8px 8px 0 0; }

li.nav-item {
  cursor: pointer; }

/* TABLE STYLES */
.table-pagination-container {
  padding: 40px 10px 0px 10px; }

.entry-length {
  padding-right: 20px; }

.entry-length-label {
  justify-content: flex-start;
  font-weight: normal;
  text-align: left;
  margin-bottom: 0;
  font-size: 1rem;
  color: #616161;
  white-space: nowrap; }

.entry-length-select {
  height: 37px;
  padding: 2px;
  margin: 0 5px;
  width: 75px; }

table.main-table thead .sorting,
table.main-table thead .sorting-asc,
table.main-table thead .sorting-desc,
table.main-table thead .sorting-asc-disabled,
table.main-table thead .sorting-desc-disabled {
  cursor: pointer;
  position: relative; }

table.main-table thead > tr > th.sorting-asc,
table.main-table thead > tr > th.sorting-desc,
table.main-table thead > tr > th.sorting,
table.main-table thead > tr > td.sorting-asc,
table.main-table thead > tr > td.sorting-desc,
table.main-table thead > tr > td.sorting {
  padding-right: 30px; }

table.main-table thead .sorting:before,
table.main-table thead .sorting:after,
table.main-table thead .sorting-asc:before,
table.main-table thead .sorting-asc:after,
table.main-table thead .sorting-desc:before,
table.main-table thead .sorting-desc:after,
table.main-table thead .sorting-asc-disabled:before,
table.main-table thead .sorting-asc-disabled:after,
table.main-table thead .sorting-desc-disabled:before,
table.main-table thead .sorting-desc-disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3; }

table.main-table thead .sorting:before,
table.main-table thead .sorting-asc:before,
table.main-table thead .sorting-desc:before,
table.main-table thead .sorting-asc-disabled:before,
table.main-table thead .sorting-desc-disabled:before {
  right: 1em;
  content: "\2191"; }

table.main-table thead .sorting:after,
table.main-table thead .sorting-asc:after,
table.main-table thead .sorting-desc:after,
table.main-table thead .sorting-asc-disabled:after,
table.main-table thead .sorting-desc-disabled:after {
  right: 0.5em;
  content: "\2193"; }

table.main-table thead .sorting-asc:before,
table.main-table thead .sorting-desc:after {
  opacity: 1; }

table.main-table thead .sorting:before,
table.main-table thead .sorting:after,
table.main-table thead .sorting-asc:before,
table.main-table thead .sorting-asc:after,
table.main-table thead .sorting-desc:before,
table.main-table thead .sorting-desc:after {
  font-size: 1.138rem;
  bottom: .5rem; }

table.main-table td.hidden {
  display: none; }

table.main-table .avatar {
  width: 40px;
  height: auto;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #FFFFFF;
  float: left; }
  table.main-table .avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #FFFFFF; }

table.main-table .contact-name,
table.main-table .sender-name {
  line-height: 3rem; }

table.main-table > tbody > tr > td {
  font-weight: 300;
  font-size: 13px; }

table.main-table > td {
  padding: 10px; }

/* PAGINATION */
.pagination .next a,
.pagination .previous a {
  border: 0; }
  .pagination .next a span,
  .pagination .previous a span {
    font-size: 2.1rem; }

/* modal */
@media only screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 1000px;
    margin: 1.75rem auto; }
  .modal-dialog.small {
    max-width: 600px; } }

.modal-content {
  position: relative;
  border-radius: 3px;
  margin: 30px auto;
  max-width: 1030px;
  min-width: 320px; }

.modal-border {
  border-bottom: 3px solid #EFEFEF; }

.modal-header h1 {
  font-size: 2.2rem; }
  .modal-header h1 span {
    display: inline-block;
    padding-right: 15px; }

.modal-header .close {
  margin-top: -13px; }

.modal-header .close .mdi {
  font-size: 2.8rem; }

.ecard-form label.input-header, .event-form label.input-header {
  font-size: 1.35rem;
  font-weight: bold;
  padding: 15px 0 10px 0; }

.ecard-form label, .event-form label {
  display: block; }

.ecard-form input, .ecard-form textarea, .ecard-form select, .event-form input, .event-form textarea, .event-form select {
  margin-left: 15px;
  width: 95%; }

.ecard-form textarea, .event-form textarea {
  min-height: 150px; }

.ecard-form img.signature, .event-form img.signature {
  width: 35%;
  height: auto; }

.ecard-form .save-changes-container, .event-form .save-changes-container {
  padding-top: 30px; }

.ecard-form .datetimepicker, .event-form .datetimepicker {
  width: 171px; }
  .ecard-form .datetimepicker .input-group-append, .event-form .datetimepicker .input-group-append {
    position: absolute;
    top: 5px;
    right: -15px;
    font-size: 2rem; }
    .ecard-form .datetimepicker .input-group-append .btn-primary, .event-form .datetimepicker .input-group-append .btn-primary {
      font-size: 1.6rem;
      padding: 8px 15px; }

.modal-body .secondary-title {
  margin: 15px 0; }

.modal-body .fixableFieldContainer {
  display: flex;
  align-items: center; }

.modal-body .fixableView {
  margin-right: 10px; }
  .modal-body .fixableView .btn {
    margin-left: 5px !important; }

.modal-body .fixableEdit {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex: 1; }
  .modal-body .fixableEdit .alert {
    flex-basis: 100%;
    margin: 0 0 5px 0; }
  .modal-body .fixableEdit button {
    margin: 0 3px; }
  .modal-body .fixableEdit input {
    padding: 0 5px;
    margin: 0 3px 0 0;
    flex: 1; }

.card-body {
  padding: 20px; }
  .card-body p.number {
    font-size: 48px;
    margin: 15px 0; }

/* event form */
.event-form input, .event-form textarea, .event-form select {
  margin-left: 0;
  width: 100%; }

.event-form .icon-container {
  padding: 0; }

/* Section Header */
.section-header {
  background: white;
  margin-top: 50px;
  /*.add-btn{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    color: white;
    background: black;
    span{
      position: relative;
      top: 10px;
      font-size: 2.1rem;
      color: white;
    }
  }*/ }
  .section-header .main-content-padding {
    padding: 10px 5%; }
  .section-header h3 {
    padding: 12px 0 10px 0;
    margin: 0;
    font-size: 1.9rem;
    color: var(--color-primary); }
  .section-header .add-btn-label {
    line-height: 50px;
    padding-right: 10px;
    font-size: 1.3rem; }
  .section-header .add-btn {
    position: relative;
    min-width: 4rem; }
  .section-header .icon {
    display: inline-block;
    padding-right: 12px;
    color: var(--color-primary); }

/* Circle Nav */
@keyframes getcrossedpos {
  0% {
    transform: rotate(90deg);
    top: 45%; }
  100% {
    transform: rotate(-45deg);
    top: 45%; } }

@keyframes getcrossedneg {
  0% {
    transform: rotate(0deg);
    top: 45%; }
  100% {
    transform: rotate(45deg);
    top: 45%; } }

@keyframes opacityScale {
  0% {
    transform: scale(1);
    opacity: 0; }
  100% {
    transform: scale(13);
    opacity: 1; } }

@keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

nav.circle {
  position: absolute;
  right: 0;
  width: 4rem;
  height: 4rem;
  z-index: initial;
  line-height: 7rem;
  border-radius: 50%;
  transition: .3s;
  background: var(--color-secondary);
  outline: none;
  z-index: 10; }
  nav.circle:hover, nav.circle:focus {
    background: #333; }
  nav.circle:before {
    transform: rotate(90deg); }
  nav.circle:before, nav.circle:after {
    position: absolute;
    top: 45%;
    display: block;
    left: 27%;
    content: "";
    width: 46%;
    height: 8%;
    background: #fff;
    transform-origin: center;
    transition: .5s; }
  nav.circle.clicked:before {
    animation: getcrossedpos .6s forwards; }
  nav.circle.clicked:after {
    animation: getcrossedneg .6s forwards; }
  nav.circle:hover {
    cursor: pointer; }
  nav.circle.clicked {
    background: #000;
    outline: none; }
  nav.circle div.circle-nav-background {
    opacity: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: var(--color-primary);
    position: absolute;
    left: 0;
    top: 0;
    transition: .4s;
    z-index: -1; }
    nav.circle div.circle-nav-background:hover, nav.circle div.circle-nav-background:focus {
      background-color: #000; }
  nav.circle .circle-nav-container {
    position: absolute;
    display: none;
    opacity: 0;
    position: absolute;
    opacity: 0;
    right: 0;
    text-align: left;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 3rem;
    top: 20px;
    width: 287px;
    border: 0;
    color: #CCC; }
    nav.circle .circle-nav-container div.icon-container {
      position: relative;
      cursor: pointer;
      background-color: transparent;
      color: white;
      padding: 14px; }
    nav.circle .circle-nav-container div.icon-container:first-child {
      left: -20px; }
    nav.circle .circle-nav-container span.icon-class {
      color: white; }
  nav.circle.clicked .circle-nav-container {
    display: block;
    animation: opacity .6s .6s 1 forwards; }
  nav.circle.clicked .circle-nav-background {
    animation: opacityScale .6s forwards; }

/* CONTACT PAGE */
.contact-content {
  padding-top: 5%; }

.contact-stats-container .counter {
  font-weight: 700;
  font-size: 2.5rem; }

.contact-stats-container .title {
  text-transform: none;
  font-size: .9231rem;
  color: #5f5f5f; }

.contact-user-display .avatar {
  position: absolute;
  left: 15px;
  top: -25px;
  border-radius: 50%;
  background-color: #FFFFFF; }
  .contact-user-display .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #FFFFFF; }

.contact-user-display .display-info {
  position: relative;
  left: 130px; }
  .contact-user-display .display-info h1 {
    padding: 0;
    margin: 0;
    font-weight: bold; }
  .contact-user-display .display-info p {
    padding-top: 2px;
    font-size: 1.1rem; }

.contact-user-details {
  padding-top: 30px;
  padding-bottom: 25px;
  margin-bottom: 0; }
  .contact-user-details .card-body {
    padding: 25px; }
  .contact-user-details table td {
    padding-right: 10px; }
  .contact-user-details table td.label {
    font-weight: bold;
    text-align: right; }
  .contact-user-details .points-of-contact {
    border-left: 1px solid #EFEFEF;
    padding-left: 35px; }
  .contact-user-details .icon-container {
    padding-bottom: 5px;
    padding-top: 0px; }

/* NEW E CARD FORM - SELECT E CARD */
.ecard-select .scroll-container {
  min-height: 500px;
  height: 500px;
  overflow-y: scroll; }
  .ecard-select .scroll-container img {
    width: 100%;
    height: auto; }
  .ecard-select .scroll-container .col-6 {
    padding: 0 7px 14px 7px; }
  .ecard-select .scroll-container .row {
    padding: 5px 0; }
  .ecard-select .scroll-container img {
    cursor: pointer; }

.ecard-select .card {
  background-color: #EEEEEE;
  padding-bottom: 25px; }

.ecard-select .card-header {
  font-size: 2rem;
  margin-top: 15px;
  font-weight: 900;
  text-transform: uppercase; }

.ecard-select .card-body p {
  padding: 0px 30px 15px 30px;
  font-size: 1.3rem; }

.ecard-select .card-body button {
  font-size: 1.35rem; }

.ecard-select .placeholder {
  min-height: 500px;
  height: 500px; }
  .ecard-select .placeholder div {
    padding: 30% 50px !important;
    font-size: 2.1rem;
    font-weight: 700; }

/* E CARD FORM - PREVIEWS */
.ecard-form-previews h1 {
  font-size: 1.1rem;
  padding: 10px 0 0px 0; }

.ecard-form-previews img {
  width: 300px;
  height: auto;
  margin-bottom: 15px; }

.ecard-form-previews .video-preview > div {
  margin: 0 auto !important;
  border: 1px solid #CCC; }

.ecard-form-previews .recipient-email-preview {
  position: relative;
  left: 45px;
  background: #F6F6F6;
  width: 80%;
  padding-bottom: 40px; }

.ecard-form-previews .email-preview-top-logo img {
  width: 30px;
  height: auto;
  padding-top: 13px;
  margin-bottom: 10px; }

.ecard-form-previews .email-preview-top {
  width: 300px;
  height: 140px;
  position: relative; }

.ecard-form-previews .email-signature-preview {
  width: 60px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  line-height: 0; }
  .ecard-form-previews .email-signature-preview img {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto; }

.ecard-form-previews .from-desk-of {
  font-size: 5.5px;
  font-family: Arial,sans-serif;
  text-transform: uppercase;
  width: 100%;
  font-weight: 700;
  text-align: center;
  color: #a5abb0;
  padding: 11px 0;
  letter-spacing: 0.5px;
  position: relative; }

.ecard-form-previews .email-preview-bot {
  margin: 0 auto;
  padding-top: 15px; }

.ecard-form-previews .email-preview-mid {
  white-space: pre-line;
  height: auto;
  padding: 10px 0;
  font-size: 8.5px;
  text-align: left;
  color: #676666;
  font-family: Georgia,serif;
  background: none;
  width: 80%;
  margin: 0 auto; }

.ecard-form-previews .email-overlay {
  width: 175px;
  margin: 0 auto;
  height: auto; }

.ecard-form-previews p.note-text {
  font-family: Arial;
  font-size: 5.5px;
  line-height: 7px;
  padding-top: 14px;
  color: #696969; }

.ecard-form-previews span.link-colored-text {
  color: #B1212D; }

.ecard-form-previews .email-preview-content {
  width: 80%;
  margin: 0 auto;
  background: white;
  padding: 7px; }

.ecard-form-previews .outer-border {
  padding: 3px;
  border-style: solid;
  border-width: 1px; }

.ecard-form-previews .inner-border {
  padding: 10px;
  border-style: solid;
  border-width: 1px; }

.ecard-form-previews .top-greeting-imagery {
  width: 80%;
  height: auto;
  margin: 0 auto; }

/* generic form */
.switch-button-hold {
  width: 70px; }

.switch-button-hold input[type="checkbox"]:checked + span label:before {
  content: 'SEND'; }

.switch-button-hold input[type="checkbox"]:checked + span label {
  float: right; }

.switch-button-hold label:before {
  content: 'HELD'; }

.switch-button-hold label {
  float: left; }

.react-select-control {
  margin-left: 15px;
  width: 95%;
  font-size: 1.1rem; }
  .react-select-control input {
    margin-left: 0; }

.edit-icon-btn {
  cursor: pointer; }
  .edit-icon-btn .icon-container {
    cursor: pointer; }

.clear-fix {
  clear: both; }

button.create-event {
  font-size: 1.5rem;
  padding: 10px 20px;
  border-radius: 10px; }

.event-type-template-table .source-thumbnail img {
  width: 90px;
  height: auto; }

.event-type-template-form label.input-header {
  font-size: 1.35rem;
  font-weight: bold;
  padding: 15px 0 10px 0; }

.event-type-template-form label {
  display: block; }

.event-type-template-form input, .event-type-template-form textarea, .event-type-template-form select {
  margin-left: 15px;
  width: 95%; }

.event-type-template-form textarea {
  min-height: 150px; }

.event-type-template-form .ecard-preview {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 30px;
  margin-bottom: 5px;
  background: #EEE; }

.event-type-template-form .ecard-selector {
  height: 400px;
  overflow-y: scroll; }

.event-type-template-form .ecard-selector div.thumbnail {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer; }
  .event-type-template-form .ecard-selector div.thumbnail.active {
    border: 3px solid var(--color-secondary); }
  .event-type-template-form .ecard-selector div.thumbnail:hover:not(.active) {
    border: 3px solid #EFEFEF; }

.ecard-select-img {
  width: 100%;
  height: auto; }

.filter-date-time {
  padding: 20px 0; }
  .filter-date-time label.month-label {
    padding-left: 15px; }
  .filter-date-time .date-select {
    width: 100px;
    display: inline-block; }
    .filter-date-time .date-select.month {
      width: 200px; }

.apply-template-container {
  display: inline-block; }

.apply-template-selector {
  width: 152px;
  display: inline-block;
  text-align: left; }

.apply-template-btn {
  margin-left: 15px;
  display: inline-block; }

.event-type-nav button {
  text-transform: capitalize;
  margin: 0 7px;
  border-radius: 10px; }

.alert {
  padding: 10px; }

.hide-element {
  display: none; }

.modal-content {
  min-height: 500px; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */ }

/* EVENT DATES TIMELINE */
.eventDateTimeline {
  margin: 0px 0 15px !important; }

.eventDateTimeline:before {
  display: none; }

.eventDateTimeline:after {
  left: 31px;
  bottom: 10px;
  z-index: 100; }

.eventDateTimeline > li {
  clear: both;
  padding-left: 30px;
  padding-top: 20px;
  border-radius: 0px;
  margin-bottom: -4px;
  background-color: white;
  padding-bottom: 20px;
  border: 5px solid white;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1); }

.eventDateTimeline > li:last-child {
  padding-bottom: 20px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px; }

.eventDateTimeline > li:last-child:after {
  height: 90%; }

.eventDateTimeline > li:before {
  z-index: 2;
  content: "";
  position: absolute;
  left: 22px;
  top: 17px;
  height: 16px;
  width: 16px;
  background-color: #FFFFFF;
  border-radius: 50%;
  border: 1px solid #f6c163; }

.eventDateTimeline > li:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 29px;
  top: 2px;
  height: 100%;
  width: 2px;
  background-color: #f6c163; }

.eventDateTimeline > li.active:after {
  top: 22px;
  height: 83%; }

.eventDateTimeline > li.active {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
  z-index: 10;
  border-radius: 7px;
  margin-bottom: -4px; }

.eventDateTimeline > li .btn-primary {
  color: #FFFFFF !important;
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important; }

.eventDateTimeline > li.scheduled {
  background: #f5faff; }

.eventDateTimeline > li.held {
  background: #fdf4f3; }

.eventDateTimeline > li.history.bounced {
  background: #fdf4f3; }

.eventDateTimeline > li.history.opened {
  background: #f4fff7; }

.eventDateTimeline > li.history.sent {
  background: #ffffec; }

.eventDateTimeline > li.not-ready {
  color: #999; }

.eventDateTimeline > li > div {
  display: inline-block;
  vertical-align: top; }

.eventDateTimeline .send-on {
  padding: 0; }
  .eventDateTimeline .send-on p {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 300;
    display: block;
    text-transform: uppercase; }
  .eventDateTimeline .send-on .month {
    font-size: 1.2rem;
    padding-bottom: 5px; }
  .eventDateTimeline .send-on .day {
    font-size: 2.4rem; }

.eventDateTimeline .send-to div.btn {
  border-radius: 7px;
  font-size: 1.2rem; }

.eventDateTimeline .send-to div.send-to-details {
  padding: 7px 0;
  font-size: 1.4rem; }

.eventDateTimeline .send-to .occurrence-note {
  font-size: 1.1rem;
  display: block; }

.eventDateTimeline .send-to .open-detail {
  display: block;
  font-size: 1.05rem;
  font-weight: 300; }

.eventDateTimeline .ecard .thumbnail,
#eventDateTable .ecard .thumbnail {
  float: left;
  padding-right: 5px;
  cursor: pointer; }

.eventDateTimeline .ecard .thumbnail img,
#eventDateTable .ecard .thumbnail img {
  max-width: 120px;
  min-width: 60px;
  height: auto;
  border: 5px solid white;
  margin-right: 11px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1); }

.eventDateTimeline .ecard .details,
#eventDateTable .ecard .details {
  float: left;
  max-width: 180px;
  min-width: 130px; }

.eventDateTimeline .ecard .details .message,
#eventDateTable .ecard .details .message {
  font-size: 0.95rem; }

.eventDateTimeline .ecard .details .header,
#eventDateTable .ecard .details .header {
  font-size: 1.1rem; }

.eventDateTimeline .ecard .details .message span,
.eventDateTimeline .ecard .details .category,
#eventDateTable .ecard .details .message span,
#eventDateTable .ecard .details .category {
  font-weight: 900;
  text-transform: uppercase; }

.eventDateTimeline .actions .send-hold {
  display: inline-block;
  padding-right: 30px; }

.eventDateTimeline .actions .edit {
  border-radius: 7px;
  font-size: 1.3rem; }

.eventDateTimeline .actions .remove, .eventDateTimeline .actions .send-now {
  margin-left: 10px;
  border-radius: 7px;
  margin-top: 4px; }

.card-border-color-primary {
  border-top-color: var(--color-secondary); }

.year-list {
  position: relative;
  z-index: 1028;
  padding: 0; }
  .year-list li {
    display: inline-block;
    padding: 7px 20px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #CCC;
    margin: 0px 20px 0px 0px;
    font-weight: 300;
    color: var(--color-secondary);
    background: white;
    cursor: pointer; }
  .year-list li.white {
    background: var(--color-secondary);
    color: white !important;
    font-weight: 700;
    color: #404040; }
  .year-list li:hover {
    background: var(--color-secondary);
    color: white !important; }

.add-contact-dialog {
  margin-left: auto;
  margin-right: auto; }
  .add-contact-dialog .card {
    background-color: transparent; }
  .add-contact-dialog h2 {
    font-weight: 700;
    color: var(--color-primary); }
  .add-contact-dialog h3 {
    width: 80%;
    text-align: center;
    margin: auto;
    border-top: 1px solid #CCC;
    padding: 12px 0; }
  .add-contact-dialog .add-event-contact-select {
    width: 90%;
    margin: auto; }
  .add-contact-dialog .add-event-form-container {
    width: 100%;
    margin: auto;
    background: white;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    padding: 4%; }
    .add-contact-dialog .add-event-form-container h3 {
      border: 0;
      border-bottom: 1px solid #CCC; }
      .add-contact-dialog .add-event-form-container h3 span {
        display: block;
        color: var(--color-primary);
        font-weight: 600; }
    .add-contact-dialog .add-event-form-container .event-form button {
      text-align: center;
      margin: 10px auto;
      display: block;
      padding: 5px 25px;
      font-size: 16px; }
    .add-contact-dialog .add-event-form-container .event-form .react-select-control {
      padding-top: 4px;
      margin-left: 0; }
  .add-contact-dialog .add-contact-option {
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    display: inline-block;
    width: 30%;
    padding: 4%;
    margin: 4% 8% 4% 8%;
    cursor: pointer;
    background: white; }
  .add-contact-dialog .add-contact-option:first-child {
    margin-left: 10%; }
  .add-contact-dialog .icon-container .icon:hover {
    background-color: var(--color-secondary); }
  .add-contact-dialog .icon-container:hover .icon-class {
    color: var(--color-secondary); }
  .add-contact-dialog .icon-container {
    cursor: pointer; }
  .add-contact-dialog .icon-container .icon {
    width: 150px;
    height: 150px;
    padding: 42px;
    background-color: var(--color-primary); }
    .add-contact-dialog .icon-container .icon span {
      font-size: 4.9rem;
      color: white; }
  .add-contact-dialog .icon-class {
    display: block;
    padding-top: 10px;
    font-size: 1.485rem;
    color: var(--color-primary); }

.add-contact-dialog.from-modal {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }
  .add-contact-dialog.from-modal h3 {
    border-top: 0; }
  .add-contact-dialog.from-modal .card .card-header {
    display: none; }

.stats-snapshot .card {
  background: white;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2); }

.stats-snapshot .counter {
  font-size: 3.5rem;
  line-height: 0;
  font-weight: 300; }

.stats-snapshot .label {
  display: block;
  position: relative;
  line-height: 15px;
  text-transform: uppercase;
  margin-left: 70px;
  font-weight: 600;
  color: #9aa7ba;
  font-size: 1.2rem; }
  .stats-snapshot .label:before {
    content: ' ';
    border-top: 2px solid #EFEFEF;
    width: 100%;
    position: absolute;
    top: -7px; }

.stats-snapshot .counter .pct {
  font-size: 24px; }

.stats-snapshot .icon {
  padding: 6px 10px 10px 10px;
  background-color: var(--color-primary);
  color: white;
  border: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 2.1rem;
  margin-right: 20px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5); }

.redtail-splash-container .redtail-logo img {
  width: 50%;
  padding: 20px; }

h2.redtail-header {
  text-align: left; }

.redtail-contacts-container {
  width: 100%;
  height: 300px;
  overflow-y: scroll; }

.redtail-contacts {
  width: 100%;
  padding-bottom: 20px; }
  .redtail-contacts td, .redtail-contacts th {
    padding: 7px;
    text-align: left; }

.contact-details-container {
  padding: 20px 10px; }
  .contact-details-container .profile, .contact-details-container .important-events {
    border-radius: 10px;
    background: white;
    width: 96%;
    min-height: 210px;
    padding: 4%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); }
    .contact-details-container .profile .avatar, .contact-details-container .important-events .avatar {
      width: 130px;
      height: auto;
      padding-right: 15px; }
      .contact-details-container .profile .avatar img, .contact-details-container .important-events .avatar img {
        width: 100%;
        height: auto;
        border: 5px solid white;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); }
  .contact-details-container .profile-details, .contact-details-container .profile-actions {
    display: inline-block; }
  .contact-details-container .profile-actions {
    float: right; }
  .contact-details-container .important-events {
    padding-bottom: 20px; }
    .contact-details-container .important-events ul {
      width: 100%; }
      .contact-details-container .important-events ul li:nth-child(odd) {
        background: #EFEFEF; }
  .contact-details-container .event-list, .contact-details-container .event-actions {
    display: inline-block;
    vertical-align: top; }
  .contact-details-container .event-list {
    width: 57%; }
    .contact-details-container .event-list div.label {
      width: 75px; }
  .contact-details-container .event-actions {
    float: right; }
  .contact-details-container h3 {
    font-size: 1.6rem;
    padding-bottom: 7px;
    font-weight: 400;
    color: var(--color-secondary);
    padding-top: 0;
    margin: 0;
    border-bottom: 1px solid #CCC; }
  .contact-details-container ul {
    list-style: none;
    display: inline-block;
    margin: 0;
    margin-top: 7px;
    padding: 0; }
    .contact-details-container ul li > div {
      display: inline-block; }
    .contact-details-container ul li div.label {
      font-weight: 600; }
    .contact-details-container ul li {
      padding: 5px;
      margin: 0;
      height: 30px;
      clear: both; }
    .contact-details-container ul div.label {
      width: 68px; }
    .contact-details-container ul div.remove {
      float: right;
      cursor: pointer; }
  .contact-details-container .profile-actions span, .contact-details-container .event-actions span {
    display: inline-block;
    padding-right: 7px; }
  .contact-details-container button {
    float: left;
    clear: both;
    display: block;
    margin: 5px;
    font-size: 1.1rem;
    border-radius: 7px;
    padding: 7px 12px; }
    .contact-details-container button.close-button {
      border-radius: 50%;
      padding: 5px 14px;
      font-size: 1.3rem;
      background: #EFEFEF;
      font-weight: bold;
      float: right; }

.filter-by {
  float: right;
  z-index: 1029; }
  .filter-by .label {
    display: inline-block; }
  .filter-by button {
    text-transform: capitalize;
    margin: 0 7px;
    border-radius: 10px; }

.contact-select-container {
  width: 350px;
  display: inline-block;
  float: left;
  z-index: 1027;
  position: relative; }
  .contact-select-container .event-type-selector {
    display: inline-block;
    width: 250px; }

.be-login .form-group.login-submit.signup-now-link {
  display: none !important; }

body.sender {
  /* SENDER HOME */ }
  body.sender .section-header {
    margin-top: 0; }

.sender-settings {
  padding-top: 20px; }
